<template>
  <div class="mainform mainform-dilog">
    <div class="mainHeader mag-btm">
      {{ dataBox.inquiryDesc }}
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form fbxj-table">
      <div class="xunjia-news">
        <span></span>询价信息
      </div>
      <div class="elrowflx">
        <el-row :gutter="20">
          <el-col class="clearfix" :span="12">
            <div class="xjtit">询价编号</div>
            <div class="xjcont"> {{dataBox.inquiryCode}} </div>
          </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">询价描述</div>
            <div class="xjcont"> {{dataBox.inquiryDesc}} </div>
          </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">询价类型</div>
            <div class="xjcont">
              {{dataBox.inquiryType=="1"?"单一供应商询价":dataBox.inquiryType=="2"?"多家供应商询价":dataBox.inquiryType=="3"?"普通竞价":dataBox.inquiryType=="4"?"反向竞价":""}}
            </div>
          </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">公司名称</div>
            <div class="xjcont"> {{dataBox.companyName}} </div>
          </el-col>
          <el-col class="clearfix" :span="12" v-if="dataBox.inquirySchema=='' || dataBox.inquirySchema!==null">
            <div class="xjtit">询价模式</div>
            <div class="xjcont">
              {{dataBox.inquirySchema=="1"?"公开身份公开报价":dataBox.inquirySchema=="2"?"公开身份隐藏报价":dataBox.inquirySchema=="3"?"隐藏身份公开报价":dataBox.inquirySchema=="4"?"隐藏身份隐藏报价":""}}
            </div>
          </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">发布人</div>
            <div class="xjcont"> {{ dataBox.createUserName }} </div>
          </el-col>
          <el-col class="clearfix" :span="12" v-if="dataBox.biddingWay=='' || dataBox.biddingWay!==null">
            <div class="xjtit">竞价方式</div>
            <div class="xjcont">
              {{dataBox.biddingWay=="1"?"单轮竞价":dataBox.biddingWay=="2"?"多轮竞价":""}}
              <span v-if="dataBox.biddingWay==2"> ({{dataBox.biddingBz}}轮) </span>
            </div>
          </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">报价截止日期</div>
            <div class="xjcont"> {{dataBox.inquiryEndTime}} </div>
          </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">报价指标</div>
            <div class="xjcont xjcont-span">
              <span :key="index" v-for="(item, index) in dataBox.targetList">
                {{ item }}
              </span>
            </div>
          </el-col>
          <el-col class="clearfix" :span="12">
              <div class="xjtit">显示报价</div>
              <el-checkbox disabled v-model="dataBox.isShowInquiry"></el-checkbox>
            </el-col>
          <el-col class="clearfix" :span="12">
            <div class="xjtit">询价文件</div>
            <div class="xjcont xjcont-span">
              <el-upload
              :on-preview="handlePreview"
                :file-list="formData.fileInfoList"
            ></el-upload>
            </div>
          </el-col>
          
        </el-row>
         <div class="baojia" style="margin-top:-20px">
          <div class="xunjia-news">
            <span></span>明细项
          </div>
            <el-table stripe :data="inquiryMaterialMap.data" style="width: 100%">
            <el-table-column v-for="(item) in inquiryMaterialMap.title" :key='item' :prop='item' :label='item'></el-table-column>
            </el-table>
        </div>
         <div class="baojia">
          <div class="xunjia-news">
            <span></span>受邀供应商
          </div>
         <el-table class="table" :data="bidSupplierList" style="width: 100%">
                <el-table-column label="序号" width="">
                  <template #default="scope">
                    {{scope.$index+1}}
                  </template>
                </el-table-column>
                <!-- <el-table-column :show-overflow-tooltip="true" prop="code" label="供应商编码" width=""></el-table-column> -->
                <el-table-column :show-overflow-tooltip="true" prop="name" label="供应商名称" width=""></el-table-column>
                <!-- <el-table-column :show-overflow-tooltip="true" prop="isAccept" label="联系人" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="id" label="手机号" width=""></el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="paymentDays" label="邮箱" width=""></el-table-column> -->
              </el-table>
        </div>
         <!-- 报价 -->
        <div class="baojia" v-if="bjShow">
          <div class="xunjia-news">
            <span></span>报价
          </div>
          <div class="elwidth">
            <quotation-Information class="quota" :formData="formData"></quotation-Information>
          </div>
        </div>
        <div class="action action-btn" v-if="isQuote==null || isQuote != 2">
          <el-button class="release" type="primary" size="medium" @click="refuse">拒绝</el-button>
          <el-button class="preservation" type="primary" size="medium" @click="accept">报价</el-button>
        </div>
      </div>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="80%" :dialogShow="dialogShow" :ID="ID" @handleClose='handleClose' @handleSave='handleSave' title="报价" componentName="InquiryDetailDialog" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '保存', action: 'handleSave', type:'primary', size: 'mini' },{label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>
  </div>
</template>
<script>
import { request, baseURL } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'IssueDetails',
  props: [],
  data: function () {
    return {
      isQuote: '',
      dialogShow: false,
      dialogOrderShow: false,
      dialogModify: false,
      dataBox: {},
      bjShow: false,
      ID: '',
      endTimeData: {
        endTime: ''
      },
      endTimeRules: {
        endTime: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ]
      },
      formData: [],
      inquiryMaterialMap: {}, // 明细项数据
      bidSupplierList: [] // 受邀供应商数据
    }
  },
  created () {
    this.ID = this.$route.query.id
    this.isQuote = this.$route.query.isQuote
    this.getIdData()
  },
  mounted () {
  },
  methods: {
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
    },
    // 根据ID请求数据
    getIdData () {
      this.ID = this.$route.query.id
      const id = this.ID
      if (id) {
        request('/api/inquiry/getMessageBySupplier/' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.dataBox = res.data
            this.formData = res.data
            if (this.formData.quoteList.length > 0) {
              this.bjShow = true
            }
            this.dataBox.isShowInquiry = this.dataBox.isShowInquiry == 1 ? true : false
            this.inquiryMaterialMap = res.data.inquiryMaterialMap
            this.bidSupplierList = res.data.inquirySupplierList
          }
        })
        // request('/api/inquiry/getMessageByPurchasing/' + id, 'get').then((res) => {
        //   if (res.code === '200') {
        //     this.formData = res.data
        //     if (this.formData.quoteList.length > 0) {
        //       this.bjShow = true
        //     }
        //   }
        // })
      }
    },
    // 拒绝
    release () {
      const inquiryId = this.$route.query.id
      const type = 2
      request('/api/inquiry/acceptOrRjectBid?' + 'inquiryId=' + inquiryId + '&' + 'type=' + type, 'get').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '已拒绝报价'
          })
          this.$router.push({
            path: '/SalesLeads/InquiryNotice'
          })
        }
      })
    },
    // 拒绝弹框
    refuse () {
      this.$confirm('确认要拒绝报价吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.release()
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 接受
    accept () {
      this.dialogShow = true
    },
    // 点击接受报价弹框里的保存按钮
    handleSave() {
      var onelist = this.$refs.queryDialog.$refs.content.$refs.form.model
      var bjyxq = this.$refs.queryDialog.$refs.content.$refs.form.model.bjyxq
      if (onelist.quoteDesc === undefined || onelist.bjyxq === undefined) {
        this.$message({
          showClose: true,
          message: '请完善报价信息',
          type: 'warning'
        })
      } else {
        if (bjyxq) {
          this.$refs.queryDialog.$refs.content.$refs.form.model.priceStartTime = dayjs(bjyxq[0]).format('YYYY-MM-DD HH:mm:ss')
          this.$refs.queryDialog.$refs.content.$refs.form.model.priceEndTime = dayjs(bjyxq[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        const obj = {
          inquiryId: this.$route.query.id,
          inquiryMaterialQuoteVoList: onelist.list,
          priceStartTime: onelist.priceStartTime,
          priceEndTime: onelist.priceEndTime,
          quoteDesc: onelist.quoteDesc,
          supplierId: '',
          fileList:onelist.fileList
        }
        request('/api/inquiry/quoteRedis', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              message: '保存成功',
              type: 'success'
            })
            this.dialogShow = false
            this.$router.push({
              path: '/SalesLeads/InquiryNotice'
            })
          }
        })
      }
    },
    // 点击接受报价弹框里的确定按钮
    action () {
      var onelist = this.$refs.queryDialog.$refs.content.$refs.form.model
      var bjyxq = this.$refs.queryDialog.$refs.content.$refs.form.model.bjyxq
      if (onelist.quoteDesc === undefined || onelist.bjyxq === undefined) {
        this.$message({
          showClose: true,
          message: '请完善报价信息',
          type: 'warning'
        })
      } else {
        if (bjyxq) {
          this.$refs.queryDialog.$refs.content.$refs.form.model.priceStartTime = dayjs(bjyxq[0]).format('YYYY-MM-DD HH:mm:ss')
          this.$refs.queryDialog.$refs.content.$refs.form.model.priceEndTime = dayjs(bjyxq[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        const obj = {
          inquiryId: this.$route.query.id,
          inquiryMaterialQuoteVoList: onelist.list,
          priceStartTime: onelist.priceStartTime,
          priceEndTime: onelist.priceEndTime,
          quoteDesc: onelist.quoteDesc,
          supplierId: '',
          fileList:onelist.fileList
        }
        request('/api/inquiry/supplierQuote', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            })
            this.dialogShow = false
            this.$router.push({
              path: '/SalesLeads/InquiryNotice'
            })
          }
        })
      }
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    handleOrderClose () {
      this.dialogOrderShow = false
    }
  },
  watch: {
    $route: {
      handler () {
        this.ID = this.$route.query.id
        this.isQuote = this.$route.query.isQuote
        this.getIdData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  :deep(.el-upload-list__item-status-label) {
    display: none!important;
  }
  :deep(.el-icon-close) {
    display: none!important;
  }
  :deep(.el-icon-close-tip) {
    display: none!important;
  }
  :deep(.el-upload) {
    display: none!important;
  }
  :deep(.el-upload-list__item:first-child) {
    margin: 0;
  }
  .mainform-dilog{
    .dialogBox .el-overlay .el-dialog{
      width: 80% !important;
    }
    .dialogBox .el-overlay .el-dialog .el-dialog__body{
      padding: 20px 40px;
    }
  }
  .fbxj-table{
    .xunjia-news{
      color: #666;
      font-size: 16px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      span{
        display: inline-block;
        width: 3px;
        background: #ccc;
        vertical-align: -4px;
        height: 20px;
        background: #4487fa;
        margin-right: 5px;
      }
    }
  }
  .feiqi{
    margin-left: 10px;
    font-size: 14px;
    border: 1px solid #F56C6C;
    padding: 0 5px 2px;
    color: #F56C6C;
  }
  .elrowflx :deep(.el-col){
    margin-bottom: 20px
  }
  .elrowflx{
    margin: 0 auto !important;
    .xjtit{
      float: left;
      margin-right: 20px;
      min-width: 120px;
      text-align: right;
      color: #666;
      font-size: 14px;
    }
    .xjcont{
      float: left;
      width: 50%;
      color: #666;
      font-size: 14px;
    }
    .mxtable{
      .el-table td{
        padding: 9px 0;
      }
      float: left;
      width: calc(100% - 140px);
    }
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
    }
    :deep(.el-table__header-wrapper .el-table__header th .cell){
      line-height: 34px;
    }
    :deep(.el-table .el-table__empty-block){
      min-height: 42px;
    }
    :deep(.el-table .el-table__empty-block .el-table__empty-text){
      line-height: 42px;
      font-size: 14px;
    }
    .action-btn{
      margin: 10px 0 50px;
    }
    .xunjia-rizhi{
      margin-bottom: 60px;
      .el-collapse-item__header{
        font-size: 16px;
        color: #666;
        border-bottom: 1px solid #e4e4e4;
        &:before {
          content: " ";
          display: inline-block;
          width: 3px;
          height: 20px;
          background: #4487fa;
          vertical-align: text-top;
          margin-right: 5px;
      }
      }
      .el-collapse{
        border-top: none;
      }
      .el-collapse-item__content{
        color: #666;
        font-size: 14px;
        padding: 10px 30px;
        .czrz:before {
          content: " ";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #4487fa;
          vertical-align: 2px;
          margin-right: 6px;
        }
      }
    }
  }
  .xbj-modify{
    :deep(.el-dialog__body){
      padding: 0;
    }
    :deep(.el-dialog__header){
      padding: 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
    }
    :deep(.el-dialog__footer){
      padding: 15px 20px;
      border: 1px solid #e4e4e4;
    }
    :deep(.el-dialog){
      width: 500px !important;
      margin: 0 !important;
    }
    :deep(.el-checkbox){
      margin-bottom: 20px;
    }
    :deep(.el-pagination){
      position: initial;
      text-align: center;
      padding: 20px 0;
    }
    .el-overlay{
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .item1{
      margin: 0;
      padding: 50px 60px;
    }
    .item2{
      border-top: 1px solid #e4e4e4;
      text-align: right;
      padding: 7px 30px 7px;
      margin-bottom: 0;
    }
  }
  .xjcont-span span{
    display: inline-block;
    margin-right: 5px;
  }
</style>
